.image-grid {
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(20%, 1fr)
  ); /* Adjust minmax value as needed */
  gap: 20px; /* Adjust gap between images as needed */
}

/* .image-item {
    padding: 2px;
    width: 100%;
    height: 100%;
  } */

/* .image {
    padding: 2px;
    width: 100%;
    height: 90%;
    object-fit: cover;
    border-radius: 24px;
  } */
/* .image-title {
    padding: 10px;
    text-align:left;
    font-size: 18px; 
    color: #ffffff; 
  } */

.modal {
  --bs-modal-border-width: none;
  --bs-modal-bg: rgba(9, 9, 9, 0);
}

.image-item {
  margin-bottom: 10px;
}

.image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.image-title {
  text-align: left;
  margin-top: 10px;
  font-size: 14px;
  /* color: #ffffff;  */
  color: #51557F;
}

.transparent-text-field {
  background-color: transparent;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid white;
  color: rgb(255, 255, 255);
}

.transparent-text-field::placeholder {
  color: rgb(255, 255, 255);
}

.transparent-text-field:focus {
  box-shadow: none;
  border-color: white;
  border-width: 0 0 1px 0;
  border-radius: 0;
  outline: none;
  background-color: transparent;
  color: rgb(255, 255, 255);
}

.svg {
  vertical-align: none !important;
}

.custom-button {
  height: 31px;
  width: 47px;
  border-radius: 15px;
  background-color: rgb(
    8,
    232,
    210
  ); /* Replace with your desired custom color */
  border: none; /* Remove border if needed */
}

.custom-button:hover {
  background-color: rgb(179, 222, 218); /* Change color on hover */
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.loading-overlay .spinner-border {
  color: #fff; /* Color of the spinner */
}
